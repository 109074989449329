import * as React from "react";
import process from "process";
import { Web3ReactProvider } from "@web3-react/core";
import Web3 from "web3";

import { Grid, GridItem, Box, Center, Image } from "@chakra-ui/react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Social from "../components/social";
import Mint from "../components/mint";
import MintEnded from "../components/mintEnded";

import freeMintGif from "../images/DEATH_GIRL_HALLOWEEN_PLACEHOLDER_GIF.gif";

import "@fontsource/vt323";

function getLibrary(provider) {
    return new Web3(provider);
}

if (typeof window !== "undefined") {
    // global.window = {}
    window.process = process;
}

const FreeMintPage = () => {
    return (
        <Web3ReactProvider getLibrary={getLibrary}>
            <Layout h="100vh">
                <Seo title="DEATHGIRL HALLOWEEN MINT" />
                <Grid templateColumns={["repeat(1, 1fr)", "repeat(12, 1fr)"]} gap="0">
                    <GridItem colSpan={5}>
                        <Center h={["auto", "100%"]}>
                            <Image src={freeMintGif} alt="DEATHGIRL HALLOWEEN MINT" />
                        </Center>
                    </GridItem>
                    <GridItem colSpan={7}>
                        <Center h={["auto", "100vh"]} pt={["24", "2"]} color="white" fontFamily="VT323" textAlign="center">
                            <Box minH="320px" w="100%" borderLeft={["none", "1px solid #fff"]} textAlign="center">
                                <Center>
                                    <MintEnded />
                                </Center>
                            </Box>
                        </Center>
                    </GridItem>
                </Grid>
                <Box clear="left" mt={["24", "-24"]}>
                    <Social />
                </Box>
            </Layout>
        </Web3ReactProvider>
    );
};

export default FreeMintPage;
