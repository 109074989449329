import * as React from "react";

import { useWeb3React } from "@web3-react/core";
import { injected } from "./wallet/Connector";
// import web3 from "web3";
// import { MerkleTree } from "merkletreejs";
import keccak256 from "keccak256";

import {
    Container,
    Stack,
    Link,
    Center,
    Heading,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Text,
    Lorem,
} from "@chakra-ui/react";
import Button from "./button";
import ConnectWallet from "./connectWallet";
import useIsFirstRender from "./hooks/useIsFirstRender";

import { ALLOWLIST_ADDRESSES, CONTRACT_ABI, CONTRACT_ADDRESS, WAITLIST_ADDRESSES, TEAM_ADDRESSES, WAITLIST_MERKLE_TREE, TEAM_MERKLE_TREE, ALLOWLIST_MERKLE_TREE } from "../freemint-config";

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const Mint = () => {
    let contract;
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isInitialized, setIsInitialized] = React.useState(false);
    const [mintText, setMintText] = React.useState("Please connect your wallet");
    const [statusText, setStatusText] = React.useState();
    const [mintActive, setMintActive] = React.useState(true);
    const [mintType, setMintType] = React.useState();
    const [transactionHash, setTransactionHash] = React.useState();
    const [mintInProgess, setMintInProgress] = React.useState(false);
    const [maxMintsAllowed, setMaxMintsAllowed] = React.useState(2);
    const isFirst = useIsFirstRender();
    const { active, account, library, activate, deactivate } = useWeb3React();
    const [mintNumber, setMintNumber] = React.useState(2);
    const parseMintNumber = (val) => {
        if (mintType === "team") return val;
        if (val > maxMintsAllowed) return maxMintsAllowed;

        return val;
    };

    const init = async () => {
        try {
            contract = new library.eth.Contract(CONTRACT_ABI, CONTRACT_ADDRESS);
            setIsInitialized(true);
        } catch (e) {
            console.log(e);
        }
    };

    const Status = () => {
        if (!statusText) return null;
        return (
            <Container>
                <Heading size="md" textTransform="uppercase" textAlign="center" fontWeight="normal" color="red" mb="2" fontFamily="VT323" letterSpacing="0.1rem">
                    {statusText}
                </Heading>
                Transaction Hash: <Link href={`https://www.etherscan.io/tx/${transactionHash}`}>{transactionHash}</Link>
            </Container>
        );
    };

    const getAllowlistProof = (address) => {
        const hashedAddr = keccak256(address);
        const addrProof = ALLOWLIST_MERKLE_TREE.getHexProof(hashedAddr);

        return addrProof;
    };

    const getWaitlistProof = (address) => {
        const hashedAddr = keccak256(address);
        const addrProof = WAITLIST_MERKLE_TREE.getHexProof(hashedAddr);

        return addrProof;
    };

    const getTeamProof = (address) => {
        const hashedAddr = keccak256(address);
        const addrProof = TEAM_MERKLE_TREE.getHexProof(hashedAddr);

        return addrProof;
    };

    if (typeof window !== "undefined") {
        // global.window = {}

        if (isFirst) {
            window.getAllowlistProof = getAllowlistProof;
            window.getWaitlistProof = getWaitlistProof;
            window.getTeamProof = getTeamProof;
        }
    }

    const mintMapping = {
        none: () => {
            alert("your address is not able to mint");
        },
        team: () => {
            const hashedAddr = keccak256(account);
            const addrProof = TEAM_MERKLE_TREE.getHexProof(hashedAddr);
            try {
                contract.methods
                    .teamMint(addrProof, mintNumber)
                    .send({ from: account })
                    .on("transactionHash", function (hash) {
                        setMintInProgress(true);
                        setTransactionHash(hash);
                        setStatusText(`Mint in progress...`);
                    })
                    .on("receipt", function (receipt) {
                        console.log("RECEIPT: ", receipt);
                    })
                    .on("confirmation", function (confirmationNumber, receipt) {
                        console.log("CONFIRMATION: ", confirmationNumber);
                        console.log("RECEIPT: ", receipt);
                    })
                    .on("error", function (error, receipt) {
                        console.log(error, receipt);
                        setStatusText(`Error - please reload and try again`);
                    })
                    .finally((receipt) => {
                        setMintInProgress(false);
                        setStatusText("Mint complete - Congrats!");
                    })
                    .catch((err) => {
                        setMintInProgress(false);
                        console.log(err);
                    });
            } catch (e) {
                console.log(e);
            }
        },

        waitlist: () => {
            const hashedAddr = keccak256(account);
            const addrProof = WAITLIST_MERKLE_TREE.getHexProof(hashedAddr);
            try {
                contract.methods
                    .waitlistMint(addrProof, mintNumber)
                    .send({ from: account })
                    .on("transactionHash", function (hash) {
                        setMintInProgress(true);
                        setTransactionHash(hash);
                        setStatusText(`Mint in progress...`);
                    })
                    .on("receipt", function (receipt) {
                        console.log("RECEIPT: ", receipt);
                    })
                    .on("confirmation", function (confirmationNumber, receipt) {
                        console.log("CONFIRMATION: ", confirmationNumber);
                        console.log("RECEIPT: ", receipt);
                    })
                    .on("error", function (error, receipt) {
                        console.log(error, receipt);
                        setStatusText(`Error - please reload and try again`);
                    })
                    .finally((receipt) => {
                        setMintInProgress(false);
                        setStatusText("Mint complete - Congrats!");
                    })
                    .catch((err) => {
                        setMintInProgress(false);
                        console.log(err);
                    });
            } catch (e) {
                console.log(e);
            }
        },

        allowlist: () => {
            const hashedAddr = keccak256(account);
            const addrProof = ALLOWLIST_MERKLE_TREE.getHexProof(hashedAddr);
            try {
                contract.methods
                    .allowlistMint(addrProof, mintNumber)
                    .send({ from: account })
                    .on("transactionHash", function (hash) {
                        setMintInProgress(true);
                        setTransactionHash(hash);
                        setStatusText(`Mint in progress...`);
                    })
                    .on("receipt", function (receipt) {
                        console.log("RECEIPT: ", receipt);
                    })
                    .on("confirmation", function (confirmationNumber, receipt) {
                        console.log("CONFIRMATION: ", confirmationNumber);
                        console.log("RECEIPT: ", receipt);
                    })
                    .on("error", function (error, receipt) {
                        console.log(error, receipt);
                        setStatusText(`Error - please reload and try again`);
                    })
                    .finally((receipt) => {
                        setMintInProgress(false);
                        setStatusText("Mint complete - Congrats!");
                    })
                    .catch((err) => {
                        setMintInProgress(false);
                        console.log(err);
                    });
            } catch (e) {
                console.log(e);
            }
        },
        // public: () => {
        //     try {
        //         contract.methods
        //             .publicMint(mintNumber)
        //             .send({ from: account })
        //             .on("transactionHash", function (hash) {
        //                 setMintInProgress(true);
        //                 setTransactionHash(hash);
        //                 setStatusText(`Mint in progress...`);
        //             })
        //             .on("receipt", function (receipt) {
        //                 console.log("RECEIPT: ", receipt);
        //             })
        //             .on("confirmation", function (confirmationNumber, receipt) {
        //                 console.log("CONFIRMATION: ", confirmationNumber);
        //                 console.log("RECEIPT: ", receipt);
        //             })
        //             .on("error", function (error, receipt) {
        //                 console.log(error, receipt);
        //                 setStatusText(`Error - please reload and try again`);
        //             })
        //             .finally((receipt) => {
        //                 setMintInProgress(false);
        //                 setStatusText("Mint complete - Congrats!");
        //             })
        //             .catch((err) => {
        //                 setMintInProgress(false);
        //                 console.log(err);
        //             });
        //     } catch (e) {
        //         console.log(e);
        //     }
        // },
    };

    const handleMintClick = async (e) => {
        if (!account) return;
        if (!isInitialized) await init();

        if (mintType === 'none') {
            alert("Your address is not able to mint this collection")
            return;
        }

        contract.methods[`${mintType}Status`]()
            .call({ from: account })
            .then((res) => {
                if (res) {
                    mintMapping[mintType]();
                } else {
                    setStatusText(`${capitalizeFirstLetter(mintType)} Mint Not Currently Active.`);
                    setMintActive(false);
                }
            });
    };

    async function connect() {
        try {
            await activate(injected);
        } catch (ex) {
            console.log(ex);
        }
    }

    React.useEffect(() => assignAddress(), [active, account]);

    function assignAddress() {
        console.log("Connected to MetaMask:", active);
        console.log("Account: ", account);
        const hashedAddr = keccak256(account);
        const onTeamList = TEAM_MERKLE_TREE.verify(TEAM_MERKLE_TREE.getHexProof(hashedAddr), hashedAddr, TEAM_MERKLE_TREE.getRoot().toString("hex"));
        const onWaitlist = WAITLIST_MERKLE_TREE.verify(WAITLIST_MERKLE_TREE.getHexProof(hashedAddr), hashedAddr, WAITLIST_MERKLE_TREE.getRoot().toString("hex"));
        const onAllowList = ALLOWLIST_MERKLE_TREE.verify(ALLOWLIST_MERKLE_TREE.getHexProof(hashedAddr), hashedAddr, ALLOWLIST_MERKLE_TREE.getRoot().toString("hex"));

        console.log("ON TEAM", onTeamList);
        console.log("ON WAIT", onWaitlist);
        console.log("ON ALLOW", onAllowList);

        setMintType(onTeamList ? "team" : onAllowList ? "allowlist" : onWaitlist ? "waitlist" : account ? "none" : undefined);
        setMintText(onTeamList ? "Dev Team Mint" : onAllowList ? "Allowlist Mint" : onWaitlist ? "Waitlist Mint" : account ? "Not Able to Mint" : "Please connect wallet.");
        setMaxMintsAllowed(onTeamList ? 2 : onAllowList ? 1 : onWaitlist ? 1 : 1);
        setMintNumber(onTeamList ? 2 : onAllowList ? 1 : onWaitlist ? 1 : 1);

        return;
    }

    return (
        <Stack>
            <Heading size="lg" textTransform="uppercase" textAlign="center" fontWeight="normal" mb="2" fontFamily="VT323" letterSpacing="0.1rem">
                {mintText}
            </Heading>
            {active && !mintInProgess && mintActive && mintType !== "none" ? (
                <Center>
                    <Container w="24" ml="auto" mr="auto">
                        <NumberInput
                            w="74px"
                            mb="4"
                            onChange={(valueString) => setMintNumber(valueString)}
                            value={mintNumber}
                            defaultValue={maxMintsAllowed}
                            min={1}
                            max={mintType === "team" ? undefined : mintType === "allowlist" ? 12 : maxMintsAllowed}
                        >
                            <NumberInputField fontSize="24px" />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </Container>
                </Center>
            ) : null}
            <Center>
                <Container>
                    {active && !mintInProgess && mintActive && mintType !== "none" && <Button w="auto" text={`MINT ${parseMintNumber(mintNumber)}`} clickHandler={handleMintClick} />}
                    {!active && !mintInProgess && <ConnectWallet handler={connect} />}
                </Container>
            </Center>
            <Center>
                <Container>
                    <Status />
                </Container>
            </Center>
            <Center>
                <Container>
                    <Text>If you reject the transaction in MetaMask, you must reload the page to attempt to mint again.</Text>
                    <Text>
                        To get merkle proof for minting from contract, <Link href="/merkle-proof">click here</Link>
                    </Text>
                </Container>
            </Center>
        </Stack>
    );
};

export default Mint;
