import * as React from "react";

import { Stack, Heading } from "@chakra-ui/react";

const MintEnded = () => {
    return (
        <Stack>
            <Heading size="lg" textTransform="uppercase" textAlign="center" fontWeight="normal" mb="2" fontFamily="VT323" letterSpacing="0.1rem">
                The Mint has ended.
            </Heading>
        </Stack>
    );
};

export default MintEnded;
